@use "_base";
@use "_colors" as colors;
@use "_mixins" as mixins;

// Very magic number. Does neat things and looks nice.  
$magic_num: 8px;

body {
    padding: 3rem 5rem;
}

section#intro {
    text-align: right;

    & h1 {
        font-size: 3vmax;
        margin-bottom: 0;
        font-family: 'Braille Normal';
    }

    & p#random-chars {
        font-size: 1vmax;
        margin-top: 0.25rem;
    }
}