@font-face {
    font-family: 'Braille Normal';
    src: url('/assets/font/BRAILLE1.ttf');
}

$magic_num: 8px;

nav.nav {
    // base styles - flex column
    // TODO: Maybe set flex row when the screen is small enough?
    // Also TODO: tap targets here might be a little fucked
    display: flex;
    flex-direction: column;

    // anchor to bottom left with absolute positioning
    &.bottom-left {
        position: absolute;
        padding: $magic_num * 3;
        bottom: 0;
        left: 0;

        // add margin to all items except for the last item
        & > * {
            color: black;
            &:not(:last-child) {
                margin-bottom: $magic_num;
            }
        }
    }
}

#ascii {
    font-family: 'Courier New', Courier, monospace;

    // changed from 1vmax due to overflow
    font-size: 0.85vmax;

    margin: {
        left: 8rem;
    }

    &.page {
        font-size: 0.35vmax;
        margin: 0; // reset from above
    }
}

.root {
    margin-left: 8rem;
}

a.younite-top {
    color: black; // reset from default blue
    position: absolute;
    top: $magic_num;
    right: $magic_num * 3; // note: this was 8px but it feels more comfortable giving it more padding on the x axis
}

details {
    & > summary {
        list-style: none;

        &::-webkit-details-marker, &::marker {
            display: none;
        }
    }
}