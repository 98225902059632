@font-face {
  font-family: "Braille Normal";
  src: url("/assets/font/BRAILLE1.ttf");
}
nav.nav {
  display: flex;
  flex-direction: column;
}
nav.nav.bottom-left {
  position: absolute;
  padding: 24px;
  bottom: 0;
  left: 0;
}
nav.nav.bottom-left > * {
  color: black;
}
nav.nav.bottom-left > *:not(:last-child) {
  margin-bottom: 8px;
}

#ascii {
  font-family: "Courier New", Courier, monospace;
  font-size: 0.85vmax;
  margin-left: 8rem;
}
#ascii.page {
  font-size: 0.35vmax;
  margin: 0;
}

.root {
  margin-left: 8rem;
}

a.younite-top {
  color: black;
  position: absolute;
  top: 8px;
  right: 24px;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker, details > summary::marker {
  display: none;
}

body {
  padding: 3rem 5rem;
}

section#intro {
  text-align: right;
}
section#intro h1 {
  font-size: 3vmax;
  margin-bottom: 0;
  font-family: "Braille Normal";
}
section#intro p#random-chars {
  font-size: 1vmax;
  margin-top: 0.25rem;
}